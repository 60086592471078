import { ReactNode } from "react";
import Label from "../atoms/label";
import { useNavigate } from "react-router-dom";

interface defaultProps {
  label: string;
  children: ReactNode;
  url?: string;
}

const Element = ({ label, children, url }: defaultProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex flex-row justify-between">
        <Label className="w-full font-bold text-[16px]">{label}</Label>
        {url && (
          <button
            onClick={() => {
              navigate(url);
            }}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        )}
      </div>
      {children}
    </div>
  );
};

export default Element;
