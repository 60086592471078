import { useNavigate } from "react-router-dom";

const UserCustomOrderCheckButton = ({
  level,
  orderId,
}: {
  level: number;
  orderId: string;
}) => {
  const navigate = useNavigate();
  return (
    // <div className="flex flex-col space-y-[8px]">
    //   <button className="h-[54px] rounded-[8px] text-white bg-[#DDDDDD] w-full pt-1">
    //     리뷰 작성
    //   </button>
    //   <button className="h-[54px] rounded-[8px] text-white bg-[#FF7300] border border-[#E16600] w-full pt-1">
    //     주문 조회하기
    //   </button>
    //   <button className="h-[54px] rounded-[8px] text-white bg-[#0085FF] border border-[#006ACB] w-full pt-1">
    //     구매 확정
    //   </button>
    // </div>
    <div className="flex flex-col space-y-2 mt-6 pb-4 border-b">
      {level === 2 && (
        <button
          className="grow  h-14 border rounded-[8px] bg-black text-white"
          onClick={() => {
            navigate(`/user/orderTasting/${orderId}`);
          }}
        >
          향 선택/피드백
        </button>
      )}
      {level > 2 && level < 5 && (
        <button className="grow  h-14 border rounded-[8px] bg-black text-white">
          구매 확정
        </button>
      )}
      {level < 3 && (
        <button className="grow  h-14 border-black border rounded-[8px] bg-white text-black">
          주문 취소
        </button>
      )}
      {/* {level === 5 && (
        <button className="grow  h-14 border-black border rounded-[8px] bg-white text-black">
          리뷰하기
        </button>
      )} */}
    </div>
  );
};

export default UserCustomOrderCheckButton;
