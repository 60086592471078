import { useEffect, useRef, useState } from "react";
import Element from "../../molecules/element";
import ContentHeader from "../../organisms/contentHeader";
import { useSwipeable } from "react-swipeable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { perfumeRecommendListApi } from "../../../api/analysis";
import { perfumeFindById } from "../../../api/perfumeFinder";
// import FooterShareBar from "../../organisms/footerShareBar";
import LoadingAnimation from "../../utils/loadingAnimation";
import html2canvas from "html2canvas";

export interface PerfumeInformationType {
  top_note: string;
  middle_note: string;
  base_note: string;
  gender: string;
  concept: string;
  image: string;
  id: number;
  brand: string;
  name: string;
}

export interface perfumeRecommendListType {
  id: number;
  perfume: {
    recommend: { id: number; reason: string }[];
  };
}

export interface perfumeInfoType {
  image: string;
  brand: string;
  name: string;
  reason: string;
  concept: string;
  top: string;
  middle: string;
  base: string;
  gender: string;
}

const PerfumeRecommendList = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [params] = useSearchParams();
  const listId = params.get("id");
  const shared = params.get("shared");
  const [perfumeList, setPerfumeList] = useState<perfumeInfoType[]>();
  const [newRecommendModal, setNewRecommendModal] = useState<boolean>(false);

  const sendApi = async () => {
    if (!listId) return;

    try {
      const data: perfumeRecommendListType = await perfumeRecommendListApi(
        Number(listId)
      );
      const recommend = data.perfume.recommend;

      // Promise.all을 사용하여 모든 비동기 작업이 완료될 때까지 대기
      const newPerfumeList = await Promise.all(
        recommend.map(async (value) => {
          const perfume: PerfumeInformationType = await perfumeFindById(
            value.id,
            true
          );
          return {
            image: perfume.image,
            brand: perfume.brand,
            name: perfume.name,
            reason: value.reason,
            concept: perfume.concept,
            top: perfume.top_note,
            middle: perfume.middle_note,
            base: perfume.base_note,
            gender: perfume.gender,
          };
        })
      );

      setPerfumeList(newPerfumeList);
      console.log(perfumeList);
    } catch (error) {
      console.error("Error fetching perfume data:", error);
    }
  };

  const captureRef = useRef<HTMLDivElement>(null);

  const handleCapture = async () => {
    if (captureRef.current) {
      try {
        const canvas = await html2canvas(captureRef.current);
        const image = canvas.toDataURL("image/png");

        // 이미지 다운로드
        const link = document.createElement("a");
        link.href = image;
        link.download = "capture.png";
        link.click();
      } catch (error) {
        console.error("캡처 중 오류 발생:", error);
      }
    }
  };

  useEffect(() => {
    sendApi();
    if (listId) localStorage.setItem("listId", listId);
  }, [listId]);

  useEffect(() => {}, [perfumeList, currentIndex]);

  useEffect(() => {
    if (Boolean(shared)) {
      setTimeout(() => {
        setNewRecommendModal(true);
      }, 5000);
    }
  }, []);

  if (!perfumeList)
    return (
      <div className="absolute top-1/2 left-1/2">
        <LoadingAnimation />
      </div>
    );

  return (
    <>
      <ContentHeader home={true}>향수 추천 결과</ContentHeader>
      <div className="flex flex-col items-center w-full mb-24">
        <div
          className="flex flex-col space-y-10 px-8 pt-2 pb-8"
          ref={captureRef}
        >
          <ImageSlider
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            images={[
              perfumeList[0].image,
              perfumeList[1].image,
              perfumeList[2].image,
            ]}
            products={[
              { brand: perfumeList[0].brand, name: perfumeList[0].name },
              { brand: perfumeList[1].brand, name: perfumeList[1].name },
              { brand: perfumeList[2].brand, name: perfumeList[2].name },
            ]}
          />
          <ReasonComponent reason={perfumeList[currentIndex].reason} />
          <PerfumeConcept concept={perfumeList[currentIndex].concept} />
          <PerfumeInformation
            top={perfumeList[currentIndex].top}
            middle={perfumeList[currentIndex].middle}
            base={perfumeList[currentIndex].base}
            gender={perfumeList[currentIndex].gender}
          />
        </div>
        {/* 이미지 저장이 이상하게 되는중이라 일단 보류 */}
        {/* <div className="flex flex-col items-end px-8">
          <button
            onClick={handleCapture}
            className="border rounded-[8px] py-[16px] px-[20px] text-[12px]"
          >
            이미지로 저장
          </button>
        </div> */}
      </div>
      {/* <FooterShareBar /> */}
      {newRecommendModal && (
        <div
          className="z-30 fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-[#00000060] flex items-center justify-center"
          onClick={(event) => {
            event.stopPropagation();
            setNewRecommendModal(false);
          }}
        >
          <div className="w-[80%] max-w-[400px] bg-white rounded-[16px] flex flex-col text-[16px] text-black p-6">
            <div className="flex flex-col text-center border-b mb-4 py-4 space-y-4">
              <div className="text-[18px] font-bold">나에게 맞는 향수 찾기</div>
              <div className="text-[14px]">
                지금 무료로 당신만을 위한
                <br />
                향수를 추천받아 보세요
              </div>
            </div>
            <button
              className="bg-[#555555] p-6 rounded-[8px] text-white font-bold"
              onClick={() => {
                navigate("/");
              }}
            >
              향수 추천 받으러가기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const ReasonComponent = ({ reason }: { reason: string }) => {
  return (
    <Element label="추천 이유">
      <div className="flex flex-col justify-center min-h-[140px] text-[14px] border rounded-[16px] p-4">
        {reason}
      </div>
    </Element>
  );
};

const PerfumeConcept = ({ concept }: { concept: string }) => {
  return (
    <Element label="향수 컨셉">
      <div className="flex flex-col justify-center min-h-[140px] text-[14px] border rounded-[16px] p-4">
        {concept}
      </div>
    </Element>
  );
};

const PerfumeInformation = ({
  top,
  middle,
  base,
  gender,
}: {
  top: string;
  middle: string;
  base: string;
  gender: string;
}) => {
  return (
    <Element label="향 정보">
      <div className="flex flex-col space-y-6 text-[14px] border rounded-[16px] px-2 py-8">
        <div className="flex flex-row">
          <div className="w-20 min-w-20 border-r-2 border-black font-bold text-center">
            탑
          </div>
          <div className="ml-4 text-pretty">{top}</div>
        </div>
        <div className="flex flex-row">
          <div className="w-20 min-w-20 border-r-2 border-black font-bold text-center">
            미들
          </div>
          <div className="ml-4 text-pretty">{middle}</div>
        </div>
        <div className="flex flex-row">
          <div className="w-20 min-w-20 border-r-2 border-black font-bold text-center">
            베이스
          </div>
          <div className="ml-4 text-pretty">{base}</div>
        </div>
        <div className="flex flex-row">
          <div className="w-20 min-w-20 border-r-2 border-black font-bold text-center">
            성별
          </div>
          <div className="ml-4">{gender}</div>
        </div>
      </div>
    </Element>
  );
};

const ImageSlider = ({
  currentIndex,
  setCurrentIndex,
  images,
  products,
}: {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  images: string[];
  products: {
    brand: string;
    name: string;
  }[];
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < images.length - 1) {
        setCurrentIndex((prev) => prev + 1);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        setCurrentIndex((prev) => prev - 1);
      }
    },
    trackMouse: true,
  });

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  return (
    <div className="w-full mb-6">
      <div
        {...handlers}
        ref={sliderRef}
        className="relative w-full overflow-hidden"
      >
        <div
          className="flex w-full transition-transform duration-300 ease-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="min-w-full flex-shrink-0 flex items-center justify-center"
            >
              <div className="w-full max-w-[300px] mx-auto aspect-square relative">
                <div className="absolute inset-0 flex items-center justify-center p-10 ">
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="max-w-full max-h-full object-contain border rounded-[16px] p-6 shadow-xl"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* 이전 버튼 */}
        <button
          onClick={handlePrevClick}
          className={`absolute left-0 top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-md ${
            currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentIndex === 0}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        {/* 다음 버튼 */}
        <button
          onClick={handleNextClick}
          className={`absolute right-0 top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-md ${
            currentIndex === images.length - 1
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          disabled={currentIndex === images.length - 1}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
      <div className="flex justify-center mb-10">
        <div className="flex gap-2">
          {images.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full ${
                currentIndex === index ? "bg-black" : "bg-black/50"
              }`}
            />
          ))}
        </div>
      </div>
      <div className="mt-4 flex flex-col items-center">
        <h2 className="text-[14px] text-gray-600">
          {products[currentIndex].brand}
        </h2>
        <p className="text-[20px] font-bold">{products[currentIndex].name}</p>
      </div>
    </div>
  );
};

export default PerfumeRecommendList;
