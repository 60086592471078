import Label from "../../atoms/label";

interface defaultProps {
  level: number;
}

const levelData: {
  ratio: number;
  comment: string;
  color: string;
}[] = [
  { ratio: 10, comment: "주문을 확인하고 있어요.", color: "#0080FF" },
  {
    ratio: 40,
    comment: "AI와 조향사가 향기를 만들고있어요.",
    color: "#0080FF",
  },
  {
    ratio: 60,
    comment: "시향지를 배송했어요. 피드백을 기다리고 있어요.",
    color: "#ff9500",
  },
  {
    ratio: 80,
    comment: "유해한 성분이 없는지 검사하고있어요.",
    color: "#0080FF",
  },
  {
    ratio: 100,
    comment: "제품 제작이 완료되었어요. 곧 만나요!",
    color: "#009900",
  },
];

const UserOrderStatusBar = ({ level }: defaultProps) => {
  if (level < 5)
    return (
      <div className="w-full space-y-[8px] p-[16px] border rounded-[8px]">
        <div className="border rounded-[4px] w-full h-[14px] p-0 overflow-hidden">
          <div
            className="h-full"
            style={{
              background: levelData[level].color,
              width: `${levelData[level].ratio}%`,
            }}
          ></div>
        </div>
        <Label>{levelData[level].comment}</Label>
      </div>
    );
  return null;
};

export default UserOrderStatusBar;
