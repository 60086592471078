import { useEffect, useState } from "react";
import Element from "../../molecules/element";
import UserCustomOrderCheckButton from "../../molecules/user/userCustomOrderCheckButton";
import UserOrderProductList from "../../molecules/user/userOrderProductList";
import UserOrderStatusBar from "../../molecules/user/userOrderStatusBar";
import { customOrderList } from "../../../api/customOrder";

export interface orderListType {
  image: string;
  order_id: string;
  status: string;
  title: string;
}

const orderStatus = [
  { status: "접수", number: 0, color: "#0080FF" },
  { status: "제조", number: 0, color: "#0080FF" },
  { status: "테스트", number: 0, color: "#FF8A00" },
  { status: "인증", number: 0, color: "#0080FF" },
  { status: "배송", number: 0, color: "#009900" },
  { status: "완료", number: 0, color: "#009900" },
];

const UserCustomOrderCheck = () => {
  const [orders, setOrders] = useState<
    | {
        orderNumber: string;
        product: {
          name: string;
          image: string;
          count: number;
          price: number;
          status: string;
        }[];
      }
    | undefined
  >();
  const [orderLevel, setOrderLevel] = useState<number>(0);

  const getOrderList = async () => {
    const data: orderListType[] = await customOrderList();
    console.log(data);
    setOrders({
      orderNumber: data[data?.length - 1].order_id,
      product: [
        {
          name: data[data?.length - 1].title,
          image: "/images/defaultDiffuserImage.jpeg",
          count: 1,
          price: 39900,
          status: data[data?.length - 1].status,
        },
      ],
    });
  };

  useEffect(() => {
    getOrderList();
  }, []);

  useEffect(() => {
    orderStatus.map((value, index) => {
      if (value.status == orders?.product[0].status) {
        console.log(index);
        return setOrderLevel(index);
      }
    });
  }, [orderLevel, orders]);

  if (orders)
    return (
      <div className="space-y-[24px]">
        <Element label="커스텀 오더 조회">
          <UserOrderStatusBar level={orderLevel} />
        </Element>
        <UserOrderProductList orderData={orders} />
        <UserCustomOrderCheckButton
          level={orderLevel}
          orderId={orders.orderNumber}
        />
      </div>
    );
  return null;
};

export default UserCustomOrderCheck;
