import { ReactNode, useState } from "react";
import Layout from "../layout";
import ContentHeader from "../organisms/contentHeader";
import { useNavigate, useParams } from "react-router-dom";
import { customOrderTesting } from "../../api/customOrder";
import LoadingAnimation from "../utils/loadingAnimation";
import { AnimatePresence, motion } from "framer-motion";

const OrderTasting = () => {
  const [tastingLevel, setTastingLevel] = useState<number>(0);
  return (
    <OrderTastingTemplate
      contentHeader={<ContentHeader>시향지 선택</ContentHeader>}
      orderTastingComment={<OrderTastingComment tastingLevel={tastingLevel} />}
      orderTastingContents={
        <OrderTastingContents
          tastingLevel={tastingLevel}
          setTastingLevel={setTastingLevel}
        />
      }
    />
  );
};

export default OrderTasting;

interface defaultProps {
  contentHeader: ReactNode;
  orderTastingComment: ReactNode;
  orderTastingContents: ReactNode;
}

const OrderTastingTemplate = ({
  contentHeader,
  orderTastingComment,
  orderTastingContents,
}: defaultProps) => {
  return (
    <Layout>
      {contentHeader}
      <div className="flex flex-col">
        {orderTastingComment}
        {orderTastingContents}
      </div>
    </Layout>
  );
};

const OrderTastingComment = ({ tastingLevel }: { tastingLevel: number }) => {
  const comment = [
    <>
      조향사가 만든 시향지는
      <br />
      어떠셨나요?
      <br />
      제일 마음에 드는 시향지를
      <br />
      선택해 주세요.
    </>,
    <>
      어떠한 점이
      <br />
      마음에 들었나요?
      <br />
      의견을 남겨주시면
      <br />
      향을 개선하는데 도움이 되어요.
    </>,
    <>
      선택하신 향으로
      <br />
      향기로운 디퓨저를 만들게요!
      <br />
      배송이 시작되면
      <br />
      문자로 알려드릴게요
    </>,
  ];
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={tastingLevel}
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -30 }}
        transition={{
          duration: 0.5,
          delay: 0.4,
          ease: "easeInOut",
        }}
      >
        <div className="text-[20px] mt-6 p-6">{comment[tastingLevel]}</div>
      </motion.div>
    </AnimatePresence>
  );
};

const OrderTastingContents = ({
  tastingLevel,
  setTastingLevel,
}: {
  tastingLevel: number;
  setTastingLevel: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const orderId = params.orderId;
  const [hover, setHover] = useState<number | undefined>();
  const [inputData, setInputData] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const comment = ["1번 시향지", "2번 시향지", "3번 시향지", "모두 별로에요"];
  const content = [
    <>
      <button
        className={`w-full h-[60px] rounded-[16px] ${
          0 === hover ? "bg-[#FF7300] text-white font-bold" : "bg-[#FFF3E5]"
        }`}
        onClick={() => {
          setHover(0);
        }}
      >
        {comment[0]}
      </button>
      <button
        className={`w-full h-[60px] rounded-[16px] ${
          1 === hover ? "bg-[#FF7300] text-white font-bold" : "bg-[#FFF3E5]"
        }`}
        onClick={() => {
          setHover(1);
        }}
      >
        {comment[1]}
      </button>
      <button
        className={`w-full h-[60px] rounded-[16px] ${
          2 === hover ? "bg-[#FF7300] text-white font-bold" : "bg-[#FFF3E5]"
        }`}
        onClick={() => {
          setHover(2);
        }}
      >
        {comment[2]}
      </button>
      <button
        className={`w-full h-[60px] rounded-[16px] ${
          3 === hover ? "bg-[#FF7300] text-white font-bold" : "bg-[#FFF3E5]"
        }`}
        onClick={() => {
          setHover(3);
        }}
      >
        {comment[3]}
      </button>
    </>,
    <>
      <div
        className={`w-full h-[60px] rounded-[16px] bg-[#FF7300] text-white font-bold flex items-center justify-center
    }`}
      >
        {comment[hover ? hover : 0]}
      </div>
      <textarea
        placeholder="이곳에서 입력해주세요."
        value={inputData}
        onChange={(event) => {
          setInputData(event.target.value);
        }}
        className="p-5 border border-black rounded-[16px] w-full h-[160px] focus:outline-none placeholder:text-black"
      />
    </>,
    <div className="flex items-center justify-center mt-50">
      <img
        width={220}
        src="https://storage.studioforu.com/uploads/Tasting_Image_80be1364f4.webp"
      />
    </div>,
  ];
  const buttonLabel = ["이 향을 선택할래요", "시향지 선택 완료", "홈으로 가기"];
  if (!orderId) return null;
  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={tastingLevel}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{
            duration: 0.5,
            delay: 0.4,
            ease: "easeInOut",
          }}
        >
          <div className="space-y-4 text-[16px] p-6 mb-[120px]">
            {content[tastingLevel]}
          </div>
        </motion.div>
      </AnimatePresence>
      <div className="fixed bottom-0 w-full sm:w-[450px] p-6 mb-6 bg-white">
        <button
          className={`w-full h-[70px] rounded-[16px] text-white text-[16px] ${
            hover != undefined ? "bg-black" : "bg-[#dddddd]"
          }`}
          onClick={async () => {
            if (tastingLevel === 2) {
              navigate("/");
            } else {
              if (tastingLevel === 1) {
                setIsLoading(true);
                await customOrderTesting({
                  orderId,
                  selectProductId: tastingLevel,
                  comment: inputData,
                });
                setIsLoading(false);
              }
              setTastingLevel(tastingLevel + 1);
            }
          }}
        >
          {buttonLabel[tastingLevel]}
        </button>
      </div>
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-[#ffffff80] flex items-center justify-center">
          <LoadingAnimation />
        </div>
      )}
    </>
  );
};
