import { customOrderList } from "../../api/customOrder";
import { useEffect, useState } from "react";
import UserOrderProductList from "../molecules/user/userOrderProductList";
import UserCustomOrderCheckButton from "../molecules/user/userCustomOrderCheckButton";

export interface orderListType {
  image: string;
  order_id: string;
  status: string;
  title: string;
}

const orderStatus = [
  { status: "접수", number: 0, color: "#0080FF" },
  { status: "제조", number: 0, color: "#0080FF" },
  { status: "테스트", number: 0, color: "#FF8A00" },
  { status: "인증", number: 0, color: "#0080FF" },
  { status: "배송", number: 0, color: "#009900" },
  { status: "완료", number: 0, color: "#009900" },
];

const OrderList = () => {
  const [orders, setOrders] = useState<Array<orderListType> | undefined>();
  const [currentPage, setCurrentPage] = useState(0);
  const ordersPerPage = 3;

  const getOrderList = async () => {
    const data = await customOrderList();
    if (!data || data.data === null) return;
    const sortedData = [...data].reverse();
    setOrders(sortedData);
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const statusLevel = (status: string) => {
    let num = 0;
    orderStatus.map((value, index) => {
      if (value.status === status) return (num = index);
    });

    return num;
  };

  // if (!orders) return null;

  // 주문 내역이 없는 경우 메시지 표시
  if (!orders || orders.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-[400px] p-4">
        <p className="text-gray-500 text-lg font-medium">
          주문 내역이 없습니다
        </p>
      </div>
    );
  }

  const startIndex = currentPage * ordersPerPage;
  const currentOrders = orders.slice(startIndex, startIndex + ordersPerPage);

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (startIndex + ordersPerPage < orders.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <div className="p-4">
      <div className="space-y-4">
        {currentOrders.map((value) => (
          <div key={value.order_id}>
            <UserOrderProductList
              orderData={{
                orderNumber: value.order_id,
                product: [
                  {
                    name: value.title,
                    image: "/images/defaultDiffuserImage.jpeg",
                    count: 1,
                    price: 80000,
                    status: value.status,
                  },
                ],
              }}
            />
            <UserCustomOrderCheckButton
              level={statusLevel(value.status)}
              orderId={value.order_id}
            />
          </div>
        ))}
      </div>

      {/* 페이지네이션 버튼 */}
      <div className="flex justify-center gap-4 mt-6">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className={`px-4 py-2 rounded-[8px] ${
            currentPage === 0 ? "bg-gray-200" : "bg-black text-white"
          }`}
        >
          이전
        </button>
        <button
          onClick={handleNextPage}
          disabled={startIndex + ordersPerPage >= (orders?.length || 0)}
          className={`px-4 py-2 rounded-[8px] ${
            startIndex + ordersPerPage >= (orders?.length || 0)
              ? "bg-gray-200"
              : "bg-black text-white"
          }`}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default OrderList;
