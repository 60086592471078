import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { perfumeRecommendRegistApi } from "../../../api/analysis";
import {
  perfumeRecommendGenderAtom,
  perfumeRecommendStoryAtom,
} from "../../../stores/perfumeRecommend/atoms";
import { useAtomValue } from "jotai";
import LoadingAnimation from "../../utils/loadingAnimation";

const PerfumeRecommendLoading = () => {
  const perfumeRecommendGender = useAtomValue(perfumeRecommendGenderAtom);
  const perfumeRecommendStory = useAtomValue(perfumeRecommendStoryAtom);
  const [listId, setListId] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [recommendState, setRecommendState] = useState(false);
  const navigate = useNavigate();
  const sendApi = async () => {
    const result = await perfumeRecommendRegistApi({
      gender: perfumeRecommendGender === "man" ? "남성" : "여성",
      query: perfumeRecommendStory,
    });
    console.log(result);
    if (result?.success) {
      setLoading(true);
      setListId(result.data.id);
      setRecommendState(true);
    } else {
      setLoading(true);
      setRecommendState(false);
    }
  };

  useEffect(() => {
    sendApi();
  }, []);

  return (
    <div className="w-full pt-[59px]">
      <div className="flex flex-col p-6">
        <AnimatePresence mode="wait">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -30 }}
            transition={{
              duration: 0.5,
              delay: 0.4,
              ease: "easeInOut",
            }}
          >
            <div className="h-[100px] text-[24px] font-bold w-full grow">
              {loading ? (
                recommendState ? (
                  <div className="text-black">AI가 취향분석을 완료했어요.</div>
                ) : (
                  <div className="text-red-800 flex flex-col">
                    AI가 취향분석을 실패했어요.
                    <div className="text-[14px] text-black font-normal">
                      설명이 자세할수록 AI가 분석하는데 도움이 돼요
                    </div>
                  </div>
                )
              ) : (
                <div className="">
                  AI가
                  <br />
                  당신의 취향을
                  <br />
                  분석하고 있어요.
                </div>
              )}
            </div>
            {loading ? (
              recommendState ? (
                <AnimatePresence mode="wait">
                  <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -30 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.4,
                      ease: "easeInOut",
                    }}
                  >
                    <img
                      src="https://storage.studioforu.com/uploads/recommend_Image2_b3c2fd6186.webp"
                      className="scale-75"
                    />
                  </motion.div>
                </AnimatePresence>
              ) : (
                <AnimatePresence mode="wait">
                  <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -30 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.4,
                      ease: "easeInOut",
                    }}
                  >
                    <img
                      src="https://storage.studioforu.com/uploads/recommend_Faim_Image_0f2248c1c5.webp"
                      className="scale-75"
                    />
                  </motion.div>
                </AnimatePresence>
              )
            ) : (
              <div className="flex pt-40 justify-center grow">
                <LoadingAnimation />
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="fixed bottom-0 w-full sm:w-[450px] p-6 bg-white">
        <button
          className={`w-full text-white rounded-2xl h-[70px] text-[20px] ${
            loading ? "bg-black" : "bg-[#C9C9C9]"
          }`}
          onClick={() => {
            if (loading)
              navigate(
                recommendState
                  ? `/recommendPerfume/list?id=${listId}`
                  : "/recommendPerfume?retry=true"
              );
          }}
          disabled={loading ? false : true}
        >
          {loading
            ? recommendState
              ? "다음 단계로"
              : "답변 수정하기"
            : "분석중 . . ."}
        </button>
      </div>
    </div>
  );
};

export default PerfumeRecommendLoading;
