import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useNaverAnalytics } from "../hooks/naverAnalytics";
import Main from "./pages/main";
import NotFound from "./pages/notfound";
import TossPayments from "./pages/tossPayments/checkout";
import TossPaymentsFail from "./pages/tossPayments/fail";
import TossPaymentsSuccess from "./pages/tossPayments/success";
import Login from "./pages/login";
import Auth from "./pages/oauth/auth";
import Recommend from "./pages/recommend";
import Order from "./pages/order";
import Search from "./pages/search";
import User from "./pages/user";
import Board from "./pages/board";
import Shop from "./pages/shop";
import OrderAccept from "./pages/orderAccept";
import OrderDetail from "./pages/orderDetail";
import OrderListPage from "./pages/orderList";
import RecommendAccept from "./pages/recommendAccept";
import PrivacyPolicy from "./pages/privacyPolicy";
import PerfumeRecommend from "./pages/perfumeRecommend/perfumeRecommend";
import PerfumeRecommendLoading from "./pages/perfumeRecommend/perfumeRecommendLoading";
import PerfumeRecommendList from "./pages/perfumeRecommend/perfumeRecommendList";
import Layout from "./layout";
import OrderTasting from "./pages/orderTasting";

/// 애널리틱스를 통해 페이지 변화를 추적하는 컴포넌트
const RouteTracker = () => {
  const location = useLocation();
  const na = useNaverAnalytics();
  const trackingId = "14080c3ed00d0f0";

  // 최초 페이지 진입 시
  useEffect(() => {
    // naver Analytics trackingId 초기화
    na.initialize(trackingId);
  }, [na]);

  // 페이지 이동 시
  useEffect(() => {
    na.pageViewTrigger();
  }, [location, na]);

  return null;
};

const Router = () => {
  return (
    <Layout>
      <BrowserRouter>
        <RouteTracker />
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/search" element={<Search />} /> */}

          {/* 로그인 관련 엔드포인트 */}
          <Route path="/login" element={<Login />} />
          <Route path="/auth" element={<Auth />} />

          {/* 마이페이지 관련 엔드포인트 */}
          <Route path="/user" element={<User />} />
          <Route path="/user/orderDetail" element={<OrderListPage />} />
          <Route path="/user/orderDetail/:orderId" element={<OrderDetail />} />
          <Route
            path="/user/orderTasting/:orderId"
            element={<OrderTasting />}
          />
          <Route path="/user/review/:orderId" element={<></>} />

          {/* 향수 추천 관련 엔드포인트 */}
          <Route path="/recommendPerfume" element={<PerfumeRecommend />} />
          <Route
            path="/recommendPerfume/loading"
            element={<PerfumeRecommendLoading />}
          />
          <Route
            path="/recommendPerfume/list"
            element={<PerfumeRecommendList />}
          />

          {/* 개인정보처리방침 */}
          {/* <Route path="/setting" element={<></>} /> */}
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

          {/* 제품 관련 엔드포인트 */}
          {/* <Route path="/product" element={<Shop />} /> */}
          {/* <Route path="/product/:productId" element={<></>} /> */}
          {/* <Route path="/cart" element={<></>} /> */}

          {/* 커스텀 오더 관련 엔드포인트 */}
          <Route path="/custom-order" element={<Recommend />} />
          <Route path="/custom-order/loading" element={<RecommendAccept />} />

          {/* 게시판 관련 엔드포인트 */}
          {/* <Route path="/board" element={<Board />} /> */}

          {/* 주문 관련 엔드포인트 */}
          <Route path="/order" element={<Order />} />
          <Route path="/orderAccept" element={<OrderAccept />} />
          <Route path="/order/payments" element={<TossPayments />} />
          <Route
            path="/order/payments/success"
            element={<TossPaymentsSuccess />}
          />
          <Route path="/order/payments/fail" element={<TossPaymentsFail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
};

export default Router;
