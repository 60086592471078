import { useParams } from "react-router-dom";
import UserOrderProductList from "../molecules/user/userOrderProductList";
import { customOrderInfo, getPayInfo } from "../../api/customOrder";
import { useEffect, useState } from "react";
import { formatPhoneNumber } from "./order/orderCustomerInput";
import UserOrderStatusBar from "../molecules/user/userOrderStatusBar";
import UserCustomOrderCheckButton from "../molecules/user/userCustomOrderCheckButton";

interface orderListType {
  image: string;
  order_id: string;
  status: string;
  title: string;
}

const orderStatus = [
  { status: "접수", number: 0, color: "#0080FF" },
  { status: "제조", number: 0, color: "#0080FF" },
  { status: "테스트", number: 0, color: "#FF8A00" },
  { status: "인증", number: 0, color: "#0080FF" },
  { status: "배송", number: 0, color: "#009900" },
  { status: "완료", number: 0, color: "#009900" },
];

const OrderInformation = () => {
  const params = useParams();
  let orderId = params.orderId;
  const [orderData, setOrderData] = useState<orderListType | undefined>();

  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryPhone, setDeliveryPhone] = useState("");
  const [deliveryMemo, setDeliveryMemo] = useState("");

  const [orderLevel, setOrderLevel] = useState<number>(0);

  const getCustomerInfo = async () => {
    const data = await getPayInfo();
    console.log(data);

    setCustomerName(data.name);
    setCustomerEmail(data.email);
    setCustomerPhone(formatPhoneNumber(data.phone));
  };

  const getOrderInfo = async () => {
    if (orderId) {
      const data = await customOrderInfo({ orderId });
      console.log(data);
      setOrderData(data);
      setDeliveryAddress(
        `${data.delivery_address.address}, ${data.delivery_address.address_detail}`
      );
      setDeliveryMemo(data.delivery_address.delivery_message);
      setDeliveryPhone(data.delivery_address.phone);
    }
  };

  useEffect(() => {
    getOrderInfo();
    getCustomerInfo();
  }, []);

  useEffect(() => {
    orderStatus.map((value, index) => {
      if (value.status == orderData?.status) {
        console.log(index);
        return setOrderLevel(index);
      }
    });
  }, [orderLevel, orderData]);

  if (!orderId || !orderData) return null;
  return (
    <>
      <div className="p-4">
        <UserOrderStatusBar level={orderLevel} />
        <div className="my-4"></div>
        <UserOrderProductList
          click={false}
          orderData={{
            orderNumber: orderId,
            product: [
              {
                name: orderData?.title,
                image: "/images/defaultDiffuserImage.jpeg",
                count: 1,
                price: 80000,
                status: orderData?.status,
              },
            ],
          }}
        />
        <UserCustomOrderCheckButton level={orderLevel} orderId={orderId} />
      </div>
      <OrderInfoList
        title="주문정보"
        content={[
          { label: "주문번호", value: orderId },
          { label: "주문자", value: customerName },
          { label: "전화번호", value: customerPhone },
          { label: "이메일", value: customerEmail },
        ]}
      />
      {deliveryAddress && deliveryMemo && deliveryPhone && (
        <OrderInfoList
          title="배송정보"
          content={[
            { label: "수령자", value: customerName },
            { label: "주소", value: deliveryAddress },
            { label: "전화번호", value: deliveryPhone },
            { label: "배송메모", value: deliveryMemo },
          ]}
        />
      )}
      <OrderInfoList
        title="결제금액"
        content={[
          { label: "총 상품금액", value: "80,000 원" },
          { label: "배송비", value: "0 원" },
          { label: "할인 금액", value: "0 원" },
          { label: "총 결제금액", value: "80,000 원" },
        ]}
      />
    </>
  );
};

export default OrderInformation;

const OrderInfoList = ({
  title,
  content,
}: {
  title: string;
  content: { label: string; value: string }[];
}) => {
  return (
    <div className="flex flex-col">
      <div className="h-[30px] bg-[#FFF3E5] px-4 flex items-center">
        {title}
      </div>
      <div className="divide-y">
        {content.map((value, index) => {
          return (
            <div className="flex flex-row h-[50px] bg-white items-center px-4">
              <div className="min-w-40">{value.label}</div>
              <div className="grow">{value.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
