import { useLocation } from "react-router-dom";
import NavigationLabel from "../molecules/navigationLabel";
import useScrollDirection from "../../hooks/scrollDirection";
import { useEffect, useRef, useState } from "react";

const NavigationBar = () => {
  const location = useLocation();
  const path = "/" + location.pathname.split("/").slice(1, 2);
  const { direction, isAtTop } = useScrollDirection();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      setIsScrolling(true);

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const bottomThreshold = 10;

      setIsAtBottom(
        documentHeight - (scrollTop + windowHeight) <= bottomThreshold
      );

      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(false);
      }, 150); // 스크롤이 멈춘 후 150ms 후에 네비게이션 바 표시
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  return (
    <div
      className={`fixed bottom-0 w-full sm:w-[450px] p-6 transition-transform duration-300 z-[20] ${
        (isAtTop || direction === "up") && !isAtBottom && !isScrolling
          ? "translate-y-0"
          : "translate-y-full"
      }`}
    >
      <div className="bottom-0 flex flex-row space-x-[16px] p-[16px] border border-[#E2E2E2] rounded-[16px] bg-[#ffffff]">
        <NavigationLabel
          focus={path === "/"}
          image="https://storage.studioforu.com/uploads/navigation_Home_77366730f5.svg"
          focusImage="https://storage.studioforu.com/uploads/navigation_Home_Focus_bba5a1814d.svg"
          label="홈"
          url="/"
        />
        <NavigationLabel
          focus={path === "/recommendPerfume"}
          image="https://storage.studioforu.com/uploads/navigation_Board_dd29698c24.svg"
          focusImage="https://storage.studioforu.com/uploads/navigation_Board_Focus_ea20b37d39.svg"
          label="향수 추천"
          url="/recommendPerfume"
        />
        <NavigationLabel
          focus={path === "/custom-order"}
          image="https://storage.studioforu.com/uploads/navigation_Ai_fd5faff3f9.svg"
          focusImage="https://storage.studioforu.com/uploads/navigation_Ai_Focus_81cfb2c5ee.svg"
          label="향 만들기"
          url="/custom-order"
        />
        <NavigationLabel
          focus={path === "/user"}
          image="https://storage.studioforu.com/uploads/navigation_User_b6e5eca6d9.svg"
          focusImage="https://storage.studioforu.com/uploads/navigation_User_Focus_d428866c6a.svg"
          label="내 정보"
          url="/user"
        />
      </div>
    </div>
  );
};

export default NavigationBar;
